html, body{
    margin: 0;
    height:100%;
    width:100%;
    background-color: #d5ad8a;
    display:flex;
}

#root{
    height:100%;
    width:100%;
    margin:auto;
    display:flex;
}

.quote{
    height:100%;
    width:100%;
    margin:auto;
    display:flex;
    color: #ffffff;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
    font-size: 3vw;
    align-items: center;
    justify-content: center;
    font-family: "Consolas";
}